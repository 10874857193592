const tool = {
  id: 15,
  link: "vangstycke",
  icon: "StraightStairs",
  title: "Vangstycke",
  content: (
    <>
      När du ska bygga en trappa finns det en smidig formel som visar om trappan
      kommer att bli skön att gå i. I verktyget visas hur väl formeln uppfylls,
      grönt är vad som brukar rekommenderas. Om du trycker på knappen med en
      lampa får du tips på antal steg som blir bäst enligt formeln.
      <br />
      <br />
      En av de enligt mig viktigaste funktionerna i appen är bilden och att den
      faktiskt ändras hela tiden. Det blir direkt tydligt hur måtten påverkar
      slutresultatet och det blir enklare att bestämma sig. Plötsligt blir det
      lite roligt att testa olika värden, när man inte behöver räkna om.
      <br />
      <br />
      Om du trycker på bilden kan du få fram mer information. I trappverktyget
      är det en ritning som du sen kan dela med någon, spara i telefonen eller
      skriva ut så att du enkelt kan skapa ditt vangstycke.
    </>
  ),
  // images: [
  //   {
  //     src: "img/snickarsnillet/Vangstycke.png",
  //     alt: "Verktyg för att planera en trappa så att den blir bekväm och räkna ut steghöjd, stegdjup och antal steg.",
  //   },
  //   {
  //     src: "img/snickarsnillet/Vangstycke_extra.png",
  //     alt: "Ritning på vangstycke för trappa.",
  //   },
  // ],
};

export default tool;
