const tool = {
  id: 13,
  link: "lopande-matt",
  icon: "RunningMeasurement",
  title: "Löpande mått",
  content: (
    <>
      Ett alternativ till att använda miniräknaren när du ska få fram flera mått
      efter varandra, som till exempel CC-mått. Personligen tycker jag att jag
      får bättre koll på att siffrorna stämmer och jag kan lättare ändra
      värdena.
      <br />
      <br />
      Under förskjutning anger du om du vill förskjuta måttet så att du kan mäta
      där det passar, eller om du till exempel vill ha måttet på andra sidan
      regeln.
      <br />
      <br />
      Under mellanrum anger du CC-, eller kant-till-kant-måttet. Om du inte vet
      detta mått kan du ta hjälp av verktyget Indelning i appen. Nu är det bara
      att plussa på under Steg. Missade du ett mått är det bara att gå tillbaka
      till tidigare steg.
    </>
  ),
};

export default tool;
