import { Outlet, Link, useParams } from "react-router-dom";
import Logo from "../components/Logo";
import MyForm from "../components/MyForm";
import Tool from "./snickarsnillet/Tool";
import tools from "./snickarsnillet/Tools.js";

const Snickarsnillet = (props) => {
  const { id } = useParams();

  const getTool = (link) => {
    return tools.find((elem) => elem.link === link);
  };

  var tool = getTool(id);
  if (tool === undefined) {
    tool = getTool("vangstycke");
  }

  return (
    <>
      <header class="small-header">
        <Link class="back-link" to="/">
          <span>&#8592; Hem</span>
        </Link>
        <Link id="logo-link" to="/">
          <Logo width="80" height="60" />
        </Link>
        <Link to="/">Beyond Code</Link>
        <div></div>
      </header>
      <main>
        <section class="container" id="snickarsnillet">
          <div class="project-title">
            <h1>Snickarsnillet</h1>
            <div class="button-group">
              <a href="https://apps.apple.com/se/app/snickarsnillet/id1539913001">
                <img
                  class="badge"
                  src="./img/app-store-badge.svg"
                  alt="App Store badge"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.infinitebits.snickarsnillet">
                <img
                  class="badge"
                  src="./img/google-play-badge.png"
                  alt="Google Play badge"
                />
              </a>
            </div>
          </div>
          <p>En app som vill göra det enklare att bygga kluriga saker.</p>
          <div class="pictures">
            <img
              src="img/snickarsnillet/Diagonal.png"
              alt="Verktyg för att räkna ut diagonalen i en rektangel"
            />
            <img
              src="img/snickarsnillet/Indelning.png"
              alt="Verktyg för att till exempel fördela brädor jämnt på ett staket"
            />
            <img
              src="img/snickarsnillet/Täcka yta.png"
              alt="Verktyg för att planera material för att täcka en yta, som till exempel ett golv eller vägg"
            />
            <img
              src="img/snickarsnillet/Vangstycke.png"
              alt="Verktyg för att planera en trappa så att den blir bekväm och räkna ut steghöjd, stegdjup och antal steg."
            />
            <img
              src="img/snickarsnillet/Vangstycke_extra.png"
              alt="Ritning på vangstycke för trappa."
            />
            <img
              src="img/snickarsnillet/Väggstomme.png"
              alt="Verktyg för att planera en väggstomme"
            />
          </div>
          <div class="tools">
            {tools.map((t) => (
              <Tool
                active={tool.link === t.link}
                key={t.id}
                link={t.link}
                icon={t.icon}
                title={t.title}
              />
            ))}
          </div>
          <div id="tool-description">
            <div>
              {tool.images !== undefined &&
                tool.images.map((t) => <img src={t.src} alt={t.alt} />)}
            </div>
            <h2>{tool.title}</h2>
            <p>{tool.content}</p>
          </div>
        </section>
      </main>
      <footer>
        <MyForm />
      </footer>
      <script type="text/javascript" src="js/form.js"></script>
    </>
  );
};

export default Snickarsnillet;
