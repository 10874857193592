const tool = {
  id: 19,
  link: "vaggstomme",
  icon: "WallFraming",
  title: "Väggstomme",
  content: (
    <>
      Detta verktyg kan hjälpa till med att räkna ut hur mycket virke som går åt
      att regla en vägg. Du kan även använda verktyget för att kapa reglarna i
      rätt längder och vinklar direkt alternativt grovkapa genom att lägga på
      lite för att sedan rita av och därmed slippa en mätrunda.
      <br />
      <br />
      Fyll i längd, höjder och detaljer om väggen har nock eller inte. När du
      sedan fyllt i CC-mått på reglarna visar verktyget hur många reglar det
      blir. Fyller du även i virkesdimensioner får du rätt mått på reglarna.
      <br />
      <br />
      Tryck på bilden i verktyget för att få fram en måttsatt ritning.
    </>
  ),
};

export default tool;
