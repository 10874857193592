import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Home_en from "./pages/Home_en";
import Snickarsnillet from "./pages/Snickarsnillet";
import Distribution from "./pages/snickarsnillet/Distribution";
import NoPage from "./pages/NoPage";

import "./App.css";

import logo from "./logo.svg";
import Logo from "./components/Logo";
import MyForm from "./components/MyForm";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route exact path="/" element={<Layout />}> */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/en" element={<Home_en />} />
        <Route path="snickarsnillet/:id" element={<Snickarsnillet />} />
        <Route path="snickarsnillet" element={<Snickarsnillet />} />
        <Route path="*" element={<NoPage />} />
        {/* </Route> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
