const tool = {
  id: 9,
  link: "lopmeter",
  icon: "LinearMeter",
  title: "Löpmeter",
  content: (
    <>
      Räknar ut hur mycket virke av given längd som behövs för att ge rätt
      löpmeter. Du kan växla för att få löpmeter, materialets längd eller antal
      bitar som svar.
      <br />
      <br /> Smidigare än att hoppa fram och tillbaka i miniräknaren.
    </>
  ),
};

export default tool;
