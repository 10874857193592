const tool = {
  id: 10,
  link: "gering",
  icon: "MiterJoint",
  title: "Gering",
  content: (
    <>
      Räknar ut vinkeln som ska ställas in på sågen för att skapa en perfekt
      geringsfog. Verktyget fungerar även för olika dimensioner där
      geringsvinklarna blir olika på den smalare och bredare biten. Är bitarna
      lika breda tar du <code>90&deg; - hörnets vinkel/2</code>, för att få fram
      vinkeln på sågen.
      <br />
      <br />
      Så låt oss göra ett hörn som är 120&deg; för att göra det lite mer
      spännande.
      <br />
      <code>90&deg; - 120&deg;/2 = 90&deg; - 60&deg; = 30&deg;</code>
      <br />
      <br />
      Detta är precis hur appen räknar i bakgrunden, med undantag för fogning av
      olika dimensioner. Så genom att använda verktyget i appen behöver du inte
      tänka så mycket utan kan fokusera på att mäta, såga och skapa.
    </>
  ),
};

export default tool;
