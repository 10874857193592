import { useState } from "react";
import ReactDOM from "react-dom/client";

function MyForm() {
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    sendData();
  };

  function sendData() {
    var form = document.getElementById("contact-form");
    var data = new FormData(form);

    fetch("https://email.beyondcode.workers.dev/", {
      method: "POST",
      body: data,
      //   mode: 'no-cors'
    })
      .then((result) => {
        if (result.status != 200) {
          throw new Error("Bad Server Response");
        }
        return result.text();
      })
      .then((response) => {
        form.reset();
      })
      .catch((error) => {
        console.log(error);
      });

    // Prevent form to submit
    return false;
  }

  return (
    <>
      <p>Kontakta mig gärna genom att fylla i formuläret nedan.</p>
      <div id="contact-wrapper">
        <form id="contact-form" onSubmit={handleSubmit}>
          <label for="name">Namn</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder=""
            required
            onChange={handleChange}
          />
          <label for="email">Mail</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder=""
            required
            onChange={handleChange}
          />
          <label for="message">Meddelande</label>
          <textarea
            id="message"
            name="message"
            placeholder=""
            required
            onChange={handleChange}
          ></textarea>
          <input type="submit" value="Skicka" />
        </form>
      </div>
    </>
  );
}

export default MyForm;
