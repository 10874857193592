import AreaCircle from "./AreaCircle";
import AreaRectangle from "./AreaRectangle";
import AreaTriangle from "./AreaTriangle";
import CoverArea from "./CoverArea";
import CrossBracing from "./CrossBracing";
import Decking from "./Decking";
import Diagonal from "./Diagonal";
import Distribution from "./Distribution";
import LinearMeter from "./LinearMeter";
import MiterJoint from "./MiterJoint";
import Rafter from "./Rafter";
import Ramp from "./Ramp";
import RunningMeasurement from "./RunningMeasurement";
import Slope from "./Slope";
import StraightStairs from "./StraightStairs";
import Trigonometry from "./Trigonometry";
import VolumeCube from "./VolumeCube";
import VolumeCylinder from "./VolumeCylinder";
import WallFraming from "./WallFraming";

const tools = [
  AreaCircle,
  AreaRectangle,
  AreaTriangle,
  CoverArea,
  CrossBracing,
  Decking,
  Diagonal,
  Distribution,
  LinearMeter,
  MiterJoint,
  Rafter,
  Ramp,
  RunningMeasurement,
  Slope,
  StraightStairs,
  Trigonometry,
  VolumeCube,
  VolumeCylinder,
  WallFraming,
];

export default tools;
