const tool = {
  id: 7,
  link: "diagonal",
  icon: "Diagonal",
  title: "Diagonal",
  content: (
    <>
      Räknar ut exakt mått på diagonalen som till exempel underlättar arbetet
      med att få till en perfekt rektangel.
    </>
  ),
};

export default tool;
