const tool = {
  id: 5,
  link: "krysstag",
  icon: "CrossBracing",
  title: "Krysstag",
  content: (
    <>
      Få hjälp med att ta fram krysstag till exempelvis staket eller kryssben
      till bord eller soffa.
      <br />
      <br />
      Först bestämmer du bredd och höjd på ditt kryss. Därefter kan du bestämma
      dimension genom att ange hur mycket av snedstaget som ska möta bordsskivan
      och golvet eller sidorna. Genom att göra på detta sättet kan du använda
      verktyget för att göra både kryssben till bord eller soffa, samt krysstag
      till exempelvis staket.
      <br />
      <br />
      Kryssben: Sätt ”tjocklek åt sidan” till 0 och ”uppåt och nedåt” till hela
      dimensionen.
      <br />
      Krysstag: Sätt båda tjocklekar till halva dimensionen. På så vis hamnar
      staget centrerat i hörnen.
      <br />
      <br />
      Om du trycker på bilden får du fram en måttsatt ritning.
    </>
  ),
};

export default tool;
