const tool = {
  id: 12,
  link: "ramp",
  icon: "Ramp",
  title: "Ramp",
  content: (
    <>
      Verktyg för tillverkning av ramp till exempelvis entréer. Hjälper dig
      räkna ut vilken lutning det blir så du enkelt kan följa standarder för
      handikappsanpassning.
      <br />
      <br />
      Fyll i höjdskillnad och hur lång du tänkte göra rampen så får du fram
      lutningen i grader, procent och förhållande. Sen kan du fylla i
      virkesdimensionen så måtten blir rätt. Tryck på bilden i verktyget så får
      du fram en måttsatt ritning.
    </>
  ),
};

export default tool;
