const tool = {
  id: 2,
  link: "area-rektangel",
  icon: "AreaRectangle",
  title: "Area rektangel",
  content: (
    <>
      Räknar ut arean för en rektangel.
      <br />
      <br />I verktyget kan du välja vad du vill utgå från av längd, bredd och
      area.
      <br />
      <br />
      Om du trycker på bilden får du fram en måttsatt ritning.
    </>
  ),
};

export default tool;
