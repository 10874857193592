const tool = {
  id: 1,
  link: "area-cirkel",
  icon: "AreaCircle",
  title: "Area cirkel",
  content: (
    <>
      Räknar ut arean för en cirkel. Du kan även se cirkelns omkrets.
      <br />
      <br />I verktyget kan du välja om du vill utgå från diameter, radie eller
      area.
      <br />
      <br />
      Om du trycker på bilden får du fram en måttsatt ritning.
    </>
  ),
};

export default tool;
