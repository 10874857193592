import { Link } from "react-router-dom";
import Logo from "../components/Logo";
import MyForm_en from "../components/MyForm_en";

const Home_en = () => {
  return (
    <>
      <header>
        <Logo width="800" height="600" />
        <h1>Beyond Code</h1>
        <p>Solo developer within games, apps and web.</p>
      </header>
      <main>
        <section id="snickarsnillet">
          <div class="project-title">
            <Link to="">
              <h2>Brainy Builder</h2>
            </Link>
            <div class="button-group">
              <a href="https://apps.apple.com/se/app/snickarsnillet/id1539913001">
                <img
                  class="badge"
                  src="./img/app-store-badge.svg"
                  alt="App Store badge"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.infinitebits.snickarsnillet">
                <img
                  class="badge"
                  src="./img/google-play-badge.png"
                  alt="Google Play badge"
                />
              </a>
            </div>
          </div>
          <p>An app that wants to simplify building complex things.</p>
          <div class="pictures">
            <img
              src="img/snickarsnillet/Diagonal.png"
              alt="Verktyg för att räkna ut diagonalen i en rektangel"
            />
            <img
              src="img/snickarsnillet/Indelning.png"
              alt="Verktyg för att till exempel fördela brädor jämnt på ett staket"
            />
            <img
              src="img/snickarsnillet/Täcka yta.png"
              alt="Verktyg för att planera material för att täcka en yta, som till exempel ett golv eller vägg"
            />
            <img
              src="img/snickarsnillet/Vangstycke.png"
              alt="Verktyg för att planera en trappa så att den blir bekväm och räkna ut steghöjd, stegdjup och antal steg."
            />
            <img
              src="img/snickarsnillet/Vangstycke_extra.png"
              alt="Ritning på vangstycke för trappa."
            />
            <img
              src="img/snickarsnillet/Väggstomme.png"
              alt="Verktyg för att planera en väggstomme"
            />
          </div>
        </section>
      </main>
      <footer>
        <MyForm_en />
      </footer>
      <script type="text/javascript" src="js/form.js"></script>
    </>
  );
};

export default Home_en;
