const tool = {
  id: 11,
  link: "taksparre",
  icon: "Rafter",
  title: "Taksparre",
  content: (
    <>
      Verktyg för tillverkning av takbalk med urtag till tak över altan, uterum
      eller liknande.
      <br />
      <br />
      Att göra urtaget på rätt ställe och vinkel är kanske som bekant inte helt
      enkelt. Är det stora dimensioner kan varje lyft upp och ner vara både
      omständigt och tungt. Detta verktyg ger dig alla mått du behöver för att
      kunna tillverka balken på marken, om du skulle behöva. Först anges
      höjdskillnad, eller önskad takvinkel. Därefter kan du ange bredd,
      överhäng, takfotshöjd, dimension, hammarband och urtag i nock. Tryck på
      lampan för att anpassa urtaget till hammarbandet.
      <br />
      <br />
      När du fått parametrarna rätt så kan du trycka på bilden för att få fram
      en ritning. Denna kan du sedan dela till andra eller skriva ut så att du
      kan börja tillverkningen. I nock girar du med samma vinkel som takvinkeln.
      Om du har en balk i nock gör du sen urtag från underkant enligt ritningen.
      För att göra urtag vid hammarband behöver du mäta fram punkten där urtaget
      börjar genom att mäta från nock eller takfot. Sen gör du urtaget enligt
      måtten.
    </>
  ),
};

export default tool;
