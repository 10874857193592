const tool = {
  id: 4,
  link: "tacka-yta",
  icon: "CoverArea",
  title: "Täcka yta",
  content: (
    <>
      Hjälper dig att räkna ut kvadratmeter och samtidigt se hur ditt material
      täcker. Verktyget kan exempelvis användas till om du ska lägga golv eller
      sätta upp gips.
      <br />
      <br />
      Börja med att fylla i storleken på ytan. Nu kan du direkt se hur många
      kvadratmeter du behöver. Beroende på vad du ska använda för material finns
      ofta en indikation på hur många kvadratmeter materialet räcker till. Vill
      du få fram mer detaljer kan du fortsätta med att fylla i mellanrum och
      materialets storlek. Dessutom kan du förskjuta varannan rad för att se hur
      det blir. Nu visas även hur många löpmeter som behövs och antal bitar så
      att du snabbt kan hämta rätt mängd material.
    </>
  ),
};

export default tool;
