const tool = {
  id: 3,
  link: "area-triangel",
  icon: "AreaTriangle",
  title: "Area triangel",
  content: (
    <>
      Räknar ut arean för en triangel.
      <br />
      <br />I verktyget kan du välja vad du vill utgå från av längd, bredd och
      area.
      <br />
      <br />
      Om du trycker på bilden får du fram en måttsatt ritning.
    </>
  ),
};

export default tool;
