const tool = {
  id: 16,
  link: "trigonometri",
  icon: "Trigonometry",
  title: "Trigonometri",
  content: (
    <>
      I detta verktyg kan du få fram längder och vinklar på en triangel där ett
      av hörnen är 90&deg;.
      <br />
      <br />
      Verktyget är utformat så att du kan få fram alla längder och vinklar i
      triangeln så länge du känner till två av dem (plus 90&deg; hörnet), vilket
      blir väldigt kraftfullt.
    </>
  ),
};

export default tool;
