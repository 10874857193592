const tool = {
  id: 6,
  link: "lagga-trall",
  icon: "Decking",
  title: "Lägga trall",
  content: (
    <>
      I likhet med verktyget Täcka yta hjälper detta verktyg till med att räkna
      ut kvadratmeter och samtidigt se hur ditt trallen skulle kunna skarvas.
      <br />
      <br />
      Börja med att fylla i storleken på ytan. Nu kan du direkt se hur många
      kvadratmeter du behöver. Vill du få fram mer detaljer kan du fortsätta med
      att fylla i mellanrum och trallens dimensioner. Nu visas även hur många
      löpmeter som behövs och antal bitar så att du snabbt kan hämta rätt mängd
      virke.
    </>
  ),
};

export default tool;
