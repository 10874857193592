const tool = {
  id: 18,
  link: "volym-cylinder",
  icon: "VolumeCylinder",
  title: "Volym cylinder",
  content: (
    <>
      Räknar ut volymen för en cylinder. Kan användas för att räkna ut volymer
      för exempelvis gjutning.
    </>
  ),
};

export default tool;
