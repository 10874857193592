const tool = {
  id: 17,
  link: "volym-kub",
  icon: "VolumeCube",
  title: "Volym kub",
  content: (
    <>
      Räknar ut volymen för en kub. Kan användas för att räkna ut volymer för
      exempelvis gjutning.
    </>
  ),
};

export default tool;
