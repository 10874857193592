const tool = {
  id: 8,
  link: "indelning",
  icon: "Distribution",
  title: "Indelning",
  content: (
    <>
      Indelning är utan tvekan det verktyg jag själv kommer tillbaka till mest.
      Verktyget är till för att få fram hur många stolpar eller brädor som
      behövs för att täcka en given sträcka. Det stannar dock inte där utan så
      fort du behöver sprida ut nånting jämnt över en sträcka, kan du använda
      Indelning.
      <br />
      <br />
      Du kan ändra vilka parametrar du ska fylla i genom att trycka på den grå
      texten med pilarna. Du kanske vet vilket mellanrum det behöver vara och
      vill se hur lång sträcka det täcker. Du kan också välja om du vill ha
      materialet ytterst eller om det ska vara mellanrum runtom. Materialet
      ytterst används exempelvis när du ska sätta upp stolpar till ett staket
      och vet var första och sista stolpen ska sitta. Mellanrum runtom använder
      du för till exempel staket med ribbor mellan stolparna.
      <br />
      <br />
      Ett tips är att använda kant-till-kant måttet när du arbetar med ett
      levande material som trä. Är antalet tillräckligt stort kan varje
      millimeter göra att det blir fel på slutet.
      <br />
      <br />
      Om du trycker på bilden när du är klar kommer du till verktyget Löpande
      mått, förberett med kant-till-kant-måttet och nu är det bara att börja
      mäta.
    </>
  ),
};

export default tool;
