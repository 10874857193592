//import "./Logo.css";

function Logo(props) {
  return (
    <svg
      id="logo-svg"
      version="1.1"
      // xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width={props.width}
      height={props.height}
      viewBox="0, 0, 400,244.921875"
    >
      <g id="svgg">
        <path
          id="path0"
          d="M87.188 107.500 C 86.255 108.433,86.538 220.579,87.474 221.080 C 88.272 221.507,164.678 221.675,166.248 221.254 C 167.124 221.019,183.594 193.137,183.594 191.890 C 183.594 191.536,180.313 185.188,176.302 177.784 C 172.292 170.379,169.011 164.131,169.011 163.899 C 169.012 163.667,172.469 157.327,176.694 149.812 C 180.918 142.296,184.375 135.770,184.375 135.310 C 184.375 134.430,169.040 107.849,168.238 107.339 C 167.402 106.807,87.722 106.966,87.188 107.500 M228.418 108.119 C 227.595 109.063,196.437 163.166,196.248 163.980 C 196.160 164.358,203.194 176.947,212.446 192.969 L 228.800 221.289 261.815 221.389 C 292.812 221.483,294.880 221.447,295.647 220.803 C 296.750 219.877,313.730 189.850,313.584 189.083 C 313.101 186.546,311.966 188.106,303.732 202.620 C 299.248 210.525,295.243 217.476,294.833 218.066 L 294.087 219.141 262.157 219.141 L 230.226 219.141 214.684 192.181 C 206.136 177.353,199.058 164.901,198.956 164.509 C 198.789 163.871,228.522 111.654,229.858 110.238 C 230.243 109.829,236.436 109.785,262.451 110.008 L 294.568 110.283 295.263 111.294 C 295.646 111.850,299.182 117.798,303.121 124.512 C 309.277 135.003,310.411 136.719,311.196 136.719 C 311.829 136.719,312.109 136.494,312.109 135.986 C 312.109 135.021,296.561 108.658,295.746 108.241 C 294.316 107.508,229.054 107.391,228.418 108.119 M174.602 122.303 C 181.942 134.896,182.124 135.259,181.596 136.323 C 181.298 136.924,177.894 143.031,174.031 149.895 L 167.008 162.373 139.494 162.632 C 124.362 162.774,106.824 162.891,100.521 162.891 L 89.063 162.891 89.063 136.393 C 89.063 121.820,89.180 109.779,89.323 109.635 C 89.466 109.492,107.017 109.375,128.325 109.375 L 167.066 109.375 174.602 122.303 M173.985 178.348 L 181.303 191.852 173.590 205.203 C 169.347 212.547,165.688 218.684,165.457 218.842 C 165.227 219.000,147.944 219.131,127.051 219.135 L 89.063 219.141 89.063 191.992 L 89.063 164.844 127.864 164.844 L 166.666 164.844 173.985 178.348 "
          stroke="none"
          fill="#000000"
          fill-rule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

export default Logo;
