import { Link } from "react-router-dom";

function Tool(props) {
  return (
    <Link
      class={props.active ? "active" : "not-active"}
      to={"/snickarsnillet/" + props.link}
      //   onClick={props.onClick}
    >
      <img
        src={"img/snickarsnillet/icons/" + props.icon + ".png"}
        alt={props.title}
      />
    </Link>
  );
}

export default Tool;
